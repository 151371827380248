import React, { useState, useEffect } from 'react';
import './UpdateCIN.css';

const UpdateCIN = () => {
  const [newCIN, setNewCIN] = useState('');
  const [userData, setUserData] = useState(null);
   // Retrieve user data from localStorage on component mount
   useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);
console.log(userData)
  const handleInputChange = (e) => {
    setNewCIN(e.target.value);
  };

  const handleSubmit = () => {
    console.log('New CIN:', newCIN);
  };

  return (
    <div className="update-cin-container">
      <h2 className="update-cin-heading">Update CIN</h2>
      <hr className="update-cin-hr" />

      <div className="update-cin-input-container">
        <h5>CIN:</h5>
        <div className='inputButtondisable'><input
          type="text"
          value={newCIN}
          onChange={handleInputChange}
          className="update-cin-input"
          placeholder='Enter CIN to Update'
        />
          <button className='updateButton' disabled>UPDATE</button></div>
      </div>
      <div className="update-cin-input-container">
        <h5>GSTIN:</h5>
        <p>{userData?.user.gstinNo}</p>
      </div>
      <div className="update-cin-input-container">
        <h5>PAN:</h5>
        <p>{userData?.user.panNo}</p>
      </div>




    </div>
  );
};

export default UpdateCIN;