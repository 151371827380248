import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import DashboardContent from "./DashboardContent";
import EnlistmentContent from "./EnlistmentContent";
import UpdateCIN from "./UpdateCIN";
import PaymentStatement from "./PaymentStatement";
import Form16A from "./Form16A";
import { useNavigate } from "react-router-dom";
import CustomAlert from "./CustomAlert";
import logod from "../assets/logod.jpg"
import UploadDocument from "./UploadDocument";

const Dashboard = ({ userData }) => {
  const navigate = useNavigate();
  const [isLoggedIn, setIsLoggedIn] = useState(true);
  const [selectedNavItem, setSelectedNavItem] = useState("dashboard");
  const [showCustomAlert, setShowCustomAlert] = useState(false);

  const handleLogout = () => {
    setIsLoggedIn(false);
    userData = null;
    navigate("/");
  };

  useEffect(() => {
    // Show the custom alert on component mount (after login)
    setShowCustomAlert(true);
  }, []);

  const handleCustomAlertOk = () => {
    setShowCustomAlert(false);
    // Additional logic after clicking "OK" (if needed)
  };
  const handleNavItemClick = (navItem) => {
    setSelectedNavItem(navItem);
  };

  if (!isLoggedIn) {
    window.location.href = "/";
  }


  return (
    <div>
      <section id="sidebar">
        <li className="brand">
          <img src={logod} alt="" />
        </li>
        <ul className="side-menu top">
          <li
            className={selectedNavItem === "dashboard" ? "active" : ""}
            onClick={() => handleNavItemClick("dashboard")}
          >
            <i className="bx bxs-dashboard"></i>
            <span className="text">Dashboard</span>
          </li>
          <li
            className={selectedNavItem === "enlistment" ? "active" : ""}
            onClick={() => handleNavItemClick("enlistment")}
          >
            <i className="bx bxs-dashboard"></i>
            <span className="text">Enlistment</span>
          </li>
          <li
            className={selectedNavItem === "uploaddoc" ? "active" : ""}
            onClick={() => handleNavItemClick("uploaddoc")}
          >
            <i className="bx bxs-dashboard"></i>
            <span className="text">Primary Reseller</span>
          </li>
          <li
            className={selectedNavItem === "updateCIN" ? "active" : ""}
            onClick={() => handleNavItemClick("updateCIN")}
          >
            <i className="bx bxs-dashboard"></i>
            <span className="text">Update CIN</span>
          </li>
          <li
            className={selectedNavItem === "paymentstatement" ? "active" : ""}
            onClick={() => handleNavItemClick("paymentstatement")}
          >
            <i className="bx bxs-dashboard"></i>
            <span className="text">Payment Statement</span>
          </li>
          <li
            className={selectedNavItem === "form16A" ? "active" : ""}
            onClick={() => handleNavItemClick("form16A")}
          >
            <i className="bx bxs-dashboard"></i>
            <span className="text">Form 16A</span>
          </li>
        </ul>
        <ul className="side-menu"></ul>
      </section>

      <section id="content">
        <nav>
          {" "}
          <li className="logout" onClick={handleLogout}>
            <i className="bx bxs-log-out-circle"></i>
            <span className="text">Logout</span>
          </li>
        </nav>

        <main>
          <div className="table-data">
            {selectedNavItem === "dashboard" && (
              <DashboardContent userData={userData} />
            )}
            {selectedNavItem === "enlistment" && <EnlistmentContent />}
            {selectedNavItem === "updateCIN" && <UpdateCIN userData={userData} />}
            {selectedNavItem === "paymentstatement" && <PaymentStatement />}
            {selectedNavItem === "form16A" && <Form16A />}
            {selectedNavItem === "uploaddoc" && <UploadDocument />}
          </div>
        </main>
      </section>
      {showCustomAlert && (
        <CustomAlert
          message="Custom alert on the dashboard!"
          onOkClick={handleCustomAlertOk}
        />
      )}

    </div>
  );
};

export default Dashboard;