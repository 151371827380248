
import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Login from './Component/Login';
import Dashboard from './Component/Dashboard';
import { useState } from 'react';
import GenerateChallan from './Component/GenerateChallan';
import Signup from './Signup';
const App = () => {
  const [userData, setUserData] = useState(null);

  // Function to set user data in App component
  const setUserDataInApp = (data) => {
    setUserData(data);
  };
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Login setUserData={setUserDataInApp} />} />
        <Route path="/dashboard" element={<Dashboard userData={userData} />} />
        <Route path="/dashboard/generate-challan" element={<GenerateChallan />} />
        <Route path="/signup" element={<Signup />} />
      </Routes>
    </Router>
  );
};

export default App;