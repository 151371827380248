import React, { useState, useEffect } from "react";
import "./dashboardContent.css";
import { ProfileDetails } from "./ProfileDetails";

const DashboardContent = () => {
  const [isDetailsOpen, setIsDetailsOpen] = useState(false);
  const [userData, setUserData] = useState(null);
  const openDetailsPopup = () => {
    setIsDetailsOpen(true);
  };

  const closeDetailsPopup = () => {
    setIsDetailsOpen(false);
  };


  // Retrieve user data from localStorage on component mount
  useEffect(() => {
    const storedUserData = localStorage.getItem("userData");
    if (storedUserData) {
      setUserData(JSON.parse(storedUserData));
    }
  }, []);



  return (

    <div className="dashboardContent">
      <div className="profileContent">
        <h4>Hello, {userData?.user?.venderName}</h4>
        <hr />
        <p>{userData?.user?.companyName}</p>
      </div>

      <div className="otherContent">
        <div>
          <h4>My Profile</h4>
          <p>Vendor Profile</p>
          <div className="detailsButton">
            <button onClick={openDetailsPopup}>My Details</button>
            <button>Update Cin</button>
          </div>
        </div>
        <div>
          <h4>My Profile</h4>
          <p>Vendor Profile</p>
          <div className="detailsButton">
            <button>Go</button>
          </div>
        </div>
        <div>
          <h4>My Profile</h4>
          <p>Vendor Profile</p>
          <div className="detailsButton">
            <button>Payment Statement</button>
            <button>FORM 16A</button>
          </div>
        </div>
      </div>
      {isDetailsOpen && (
        <div className="popupBackground">
          <div className="modalContainer">
            <ProfileDetails profileDetails={userData?.user} />
            <button onClick={closeDetailsPopup}>Close</button>
          </div>
        </div>
      )}
    </div>
  );
};

export default DashboardContent;
