import React from 'react'
import "./signup.css"
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import axios from 'axios';
export default function Signup() {
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        venderId: '',
        venderName: '',
        mobileNumber: '',
        panNo: '',
        password: '',
        userName: '',
        email: '',
        gstinNo: '',
        cinNo: '',
        companyName: '',
    });

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });
    };
    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await axios.post('https://venderform.onrender.com/user/signup', formData);

          
                // You can redirect or perform other actions upon successful form submission
                navigate("/")
            
        } catch (error) {
            console.error('Error:', error);
        }
    };
    return (
        <div className='signup'>
            <div className='signup-container'>
                <h2>Create User</h2>
                <form className='signupForm' action="" onSubmit={handleSubmit}>
                    <input type="text" placeholder='Vendor ID' name="venderId" value={formData.venderId}
                        onChange={handleChange} />
                    <input type="text" placeholder='Vendor Name' name="venderName" value={formData.venderName}
                        onChange={handleChange} />
                    <input type="text" placeholder='Mobile Number' name="mobileNumber" value={formData.mobileNumber}
                        onChange={handleChange} />
                    <input type="text" placeholder='Username' name='userName' value={formData.userName}
                        onChange={handleChange} />
                    <input type="text" placeholder='Password' name='password' value={formData.password}
                        onChange={handleChange} />
                    <input type="text" placeholder='PAN Number' name='panNo' value={formData.panNo}
                        onChange={handleChange} />
                    <input type="text" placeholder='Email' name='email' value={formData.email}
                        onChange={handleChange} />
                    <input type="text" placeholder='GST Number' name='gstinNo' value={formData.gstinNo}
                        onChange={handleChange} />
                    <input type="text" placeholder='CIN Number' name='cinNo' value={formData.cinNo}
                        onChange={handleChange} />
                    <input type="text" placeholder='Company Name' name='companyName' value={formData.companyName}
                        onChange={handleChange} />
                    <button type="submit">Signup</button>
                </form>
            </div>

        </div>
    )
}
