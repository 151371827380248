import React from 'react'
import "./enlistment.css"
const EnlistmentContent = () => {
  return (
    <div className='enlistment'>
      <button>+ Apply</button>

      <div className='listContent'>
        <div>
          <h4>Active Enlistment</h4>
          <hr />
          <p>Product Count: <b>763</b></p>
          <p>Category Count: <b>51</b></p>
          <p>Brand Count: <b>1</b></p>
        </div>
        <div>
          <h4>Inactive Enlistment</h4>
          <hr />
          <p>You have no active enlistment</p>
        </div>
      </div>
    </div>

  )
}

export default EnlistmentContent