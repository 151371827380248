import React from "react";
import './UploadDocument.css'

const UploadDocument = () => {
  return (
    <div className="uplodadoc">
      <div className="uploaddoc-content">
      
          <label htmlFor="gstNumber">GST Number:</label>
          <input type="text" id="gstNumber" />
        
          <label htmlFor="panNumber">PAN Number:</label>
          <input type="text" id="panNumber" />
        
          <label htmlFor="itrNumber">ITR Number:</label>
          <input type="text" id="itrNumber" />
      
          <label htmlFor="turnover">Turnover Amount:</label>
          <input type="text" id="turnover" />
      
          <label htmlFor="gstNumber">Menufacturing License:</label>
          <input type="text" id="gstNumber" />
       
          <label htmlFor="panNumber">Incorporation Date:</label>
          <input type="text" id="panNumber" />
        
          <label htmlFor="itrNumber">TAN Number:</label>
          <input type="text" id="itrNumber" />
      
          <label htmlFor="turnover">IEC Number:</label>
          <input type="text" id="turnover" />
       
        <button>Upload</button>
      </div>
    </div>
  );
};

export default UploadDocument;