import React from 'react'
import "./form16A.css"
const Form16A = () => {
  return (
    <div className='form16a'>
      <h2>Download Form 16A</h2>
      <hr />
      <div className='fillForm'>
        <div>
          <h5>Fiscal Year: </h5>
          <select>
            <option value={"2022-2023"}>2022-2023</option>
            <option value={"2022-2023"}>2022-2023</option>
            <option value={"2022-2023"}>2022-2023</option>
          </select>
        </div>
        <div>
          <h5>Quarter: </h5>
          <select>
            <option value={"2022-2023"}>2022-2023</option>
            <option value={"2022-2023"}>2022-2023</option>
            <option value={"2022-2023"}>2022-2023</option>
          </select>
        </div>
      </div>
      <hr/>
 <button>Submit</button>
    </div>
  )
}

export default Form16A