import React, { useState } from "react";
import "./paymentDetails.css";
import { useNavigate } from "react-router-dom";

const PaymentStatement = () => {
  const navigate = useNavigate();
  const [fiscalYear, setFiscalYear] = useState("");
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleInputChange = (e, type) => {
    const value = e.target.value;
    switch (type) {
      case "fiscalYear":
        setFiscalYear(value);
        break;
      case "startDate":
        setStartDate(value);
        break;
      case "endDate":
        setEndDate(value);
        break;
      default:
        break;
    }
  };

  const handleSubmit = () => {
    // Handle the submission logic here
    console.log("Fiscal Year:", fiscalYear);
    console.log("Start Date:", startDate);
    console.log("End Date:", endDate);
    // You can perform further actions with the selected values
  };

  return (
    <div className="paymentDetails">

      <div className="manageAccount">
        <p className="manageAcc">Manage Account</p>
        <p>Virtual Account Number: <b>086705010440</b></p>
        <p>Bank Name: <b>ICICI</b></p>
       

      </div>

      <div className="manageAccount">
        <p className="manageAcc">Account Balance and Status</p>
        <p>Account Status: <b>Active</b></p>
        <p>Threshold Limit: <b>10000 INR</b></p>
        <p>Available Balance : <b>10000 INR</b></p>

        <div className="paymentButtons">
         <button onClick={()=>{navigate("/dashboard/generate-challan")}}>Generate Challan</button>
          <button>Update Balance</button>
          <button>Recalculate Threshold</button>
          <button>View Statement</button>
        </div>
      </div>

      <div className="manageAccount">
        <p className="manageAcc">Update UTR Number</p>
       <div className="updateUtr">
        <input type="text" placeholder="Update UTR No." />
        <button>Submit</button>
       </div>

      </div>
    </div>
  );
};

export default PaymentStatement;
