import React from 'react'
import "./GenerateChallan.css";
import icici from "../assets/icici.png";
import gemLogo from "../assets/gem-logo.jpg";
import logod from "../assets/logod.jpg";
const GenerateChallan = () => {
    return (
        <>
        <div className='generateChallan'>
            <div className='challanNav'>
                <div className='iciciLogo'><img src={icici} alt="" /></div>
            </div>
            <div className='challanContent'>
                <div className='ChallanImg'>
                    <img src={gemLogo} alt="" />
                    <img src={logod} alt="" />
                </div>
                <div className='ChallanContent'>
                    <h2>GeM Business Cockpit ICICI Bank VAN</h2>
                    <h2>GeM Seller VAN: Key Information</h2>

                    <div className='contentChallan'>
                        <p>Kindly note your registered Virtual Account No. details for GeM Business Cockpit</p>
                        <p>Beneficiary Name: <b>GeM Business Cockpit</b></p>
                        <p>Virtual Account No.: <b>086705010440</b></p>
                        <p>Please initiate a transaction via NEFT from your existing bank account to the above mentioned details. Transaction amount should be equal to the Business Cockpit. Please ensure that you initiate the payment before 06:00 PM.</p>
                    </div>
                    <i>If you are an ICICI Bank Account holder, please follow the below path:</i>
                    <div className='contentChallan'>
                        <p>Login to ICICI Bank CIB[For Current Acc] or RIB [For Saving Acc] -> Pay to Virtual Account -> Add the Virtual Account No. as shared by GeM -> Add Beneficiary-> Submit</p>
                        <p>Click on "Submit UTR" -> Enter UTR NO - Submit</p>
                        <p>Please initiate the payment post 30 minutes of confirming the Payee.</p>

                    </div>
                    <div className='contentChallan'>
                        <p>**ICICI Bank account holders can only initiate their payment from their Internet Banking or Mobile Banking via NEFT/RTGS</p>

                    </div>
 
                </div>
            </div>
        </div>
        <div className='copyright'>
            <small>Copyright © 2022 All Rights Reserved</small>
            <small>Visit ICICI Bank : www.icicibank.com</small>
        </div>
        </>
    )
}

export default GenerateChallan