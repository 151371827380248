// CustomAlert.jsx
import React from "react";
import "./CustomAlert.css";
import loginpop from '../assets/loginpop.jpeg'
const CustomAlert = ({ message, onOkClick }) => {
  return (
    <div className="custom-alert-overlay">
      <div className="custom-alert">
        <div className="alert-content">
          <img src={loginpop} alt="" />
          <button onClick={onOkClick}>OK</button>
        </div>
      </div>
    </div>
  );
};

export default CustomAlert;
