import React from "react";
import "./profileDetails.css";
export const ProfileDetails = ({profileDetails}) => {
  return (
    <div className="profileDetails">
      <h4>Profile</h4>
      <hr />
      <div className="detailsTable">
        <div>
          <h4>Vendor ID</h4>
          <p>{profileDetails?.venderId}</p>
        </div>
        <div>
          <h4>Vendor Name</h4>
          <p>{profileDetails?.venderName}</p>
        </div>
        <div>
          <h4>Company</h4>
          <p>{profileDetails?.companyName}</p>
        </div>
        <div>
          <h4>GSTIN</h4>
          <p>{profileDetails?.gstinNo}</p>
        </div>
        <div>
          <h4>PAN</h4>
          <p>{profileDetails?.panNo}</p>
        </div>
        <div>
          <h4>Email</h4>
          <p className="paraButton">{profileDetails?.email} <button>CHANGE</button> </p>
        </div>
        <div>
          <h4>Mobile</h4>
          <p className="paraButton">{profileDetails?.mobileNumber}<button>CHANGE</button></p>
        </div>
        <div>
          <h4>CIN No.</h4>
          <p>{profileDetails?.cinNo}</p>
        </div>
      </div>
    </div>
  );
};
